import AxiosAPIInterface from "./axios-api-interface";

export default class LoginAPI extends AxiosAPIInterface{
    constructor(){
        super({resoucePath:"/login"})
    }
    login(Authorization){
        return this.post({headers:{Authorization}})
    }
    refreshToken(body){
        return this.post({path:"/token/refresh",body})
    }
}