import AbstarctAPIInteface from "./abstarct-api-interface";
import { parseCookies } from "nookies";
import Axios from "axios";
const cookies = parseCookies();

export default class AxiosAPIInterface extends AbstarctAPIInteface {
	constructor({ resoucePath, baseUrl }) {
		super();
		this._resouceName = resoucePath || "";
		this._baseUrl = baseUrl || process.env.FSM_REST_ENDPOINT;
		this._accessToken = cookies["token"] || "";
		this._header = {
			access_token: `bearer ${this._accessToken}`
		};
		this._axios = Axios.create({
			baseURL: this._baseUrl + this._resouceName,
			headers: this._header
		});
	}
	get({ path = "", params = {}, headers = {} }) {
		return this._axios
			.get(path, { headers, params })
			.then(response => response.data.data);
	}
	post({ path = "", body = {}, params = {}, headers = {} }) {
		return this._axios
			.post(path, body, {headers, params })
			.then(response => response.data.data);
	}
	put({ path = "", body = {}, params = {} }) {
		return this._axios
			.put(path, body, { params })
			.then(response => response.data.data);
	}
	patch({ path = "", body = {}, params = {} }) {
		return this._axios
			.patch(path, body, { params })
			.then(response => response.data.data);
	}
	delete({ path = "", params = {} }) {
		return this._axios
			.delete(path, { params })
			.then(response => response.data.data);
	}
}
