export default class AbstarctAPIInteface {
	constructor() {
		if (this.constructor === AbstarctAPIInteface)
			throw new Error(" Object of Abstract Class cannot be created");
	}
	get() {
		throw new Error("Abstract method has no implementation");
	}
	post() {
		throw new Error("Abstract method has no implementation");
	}
	put() {
		throw new Error("Abstract method has no implementation");
	}
	patch() {
		throw new Error("Abstract method has no implementation");
	}
	delete() {
		throw new Error("Abstract method has no implementation");
	}
}
